

















import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'referral-user-infos': () => import('@/modules/referral/components/referral-user-infos.vue'),
    'referral-dao-infos': () => import('@/modules/referral/components/referral-dao-infos.vue'),
  },
})
export default class extends Vue {
  tab = null
}
